<template>
    <lay-row space="10">
        <lay-col md="24">
            <lay-panel>
                <lay-form :model="search" style="text-align: start" size="sm">
                    <lay-form-item label="代理账号" prop="username" mode="inline">
                        <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
                    </lay-form-item>
                    <lay-form-item mode="inline">
                        <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
                    </lay-form-item>

                </lay-form>
                <div style="margin-bottom: 10px;text-align: left;">
                    <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
                </div>

                <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
                    v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

                    <template #state="{ row }">
                        <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)"
                            onswitch-text="正常" unswitch-text="禁用"></lay-switch>
                    </template>
                    <template #invitationCode="{ row }">
                        <lay-button size="xs" type="primary" @click="copyToClipboard(row.invitationCode)">复制链接</lay-button> 
                    </template>


                    <template v-slot:operator="{ row }"> 
                        <lay-button size="xs" type="primary" @click="check(row)">下级</lay-button>
                        <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
                        <lay-button size="xs" type="danger" @click="oneClickLogin(row)">一键登录</lay-button>

                    </template>

                    <template #verify="{ row }">

                        <p v-if="row.verify == '1'">
                            首充分佣
                        </p>
                        <p v-else-if="row.verify == '2'">
                            长期分佣
                        </p>
                    </template>


                </lay-table>



            </lay-panel>

        </lay-col>

    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['450px', '630px']" :btn="action11" title="添加/修改">
        <div style="padding: 20px;">
            <lay-form :model="agent" ref="layFormRef11" :pane="true">
                <lay-form-item label="账户" prop="userName">
                    <lay-input v-model="agent.userName" :disabled="isDisabled" placeholder="请输入账号"></lay-input>
                </lay-form-item>
                <lay-form-item label="密码" prop="password">
                    <lay-input v-model="agent.password" type="password" placeholder="无需修改请置空"></lay-input>
                </lay-form-item>
        

                <lay-form-item label="分成方式" prop="verifyArr">
                    <lay-select v-model="agent.verify" :multiple="false">
                        <lay-select-option value="1" label="首充分佣"></lay-select-option>
                        <lay-select-option value="2" label="长期分佣"></lay-select-option>
                    </lay-select>
                </lay-form-item>

                <lay-form-item label="分成比例" prop="proportion">
                    <lay-input v-model="agent.proportion"  placeholder="请输入分成比例（百分比）">

                    </lay-input>
                </lay-form-item>
                <lay-form-item label="备注" prop="generalRemark">
                    <lay-textarea placeholder="请输入备注" v-model="agent.generalRemark"></lay-textarea>
                </lay-form-item>

            </lay-form>
        </div>
    </lay-layer>


    <lay-layer v-model="isGeneralAgent" :shade="false" :area="['850px', '630px']"  title="下级信息">
      <div style="padding: 10px; background-color: #FAFAFA; text-align: center;" >
        <lay-row space="10"> 
        <lay-table :page="pageAgent" :height="'100%'" :columns="columnsAgent" :loading="loading" :data-source="dataSourceAgent"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

            <template #state="{ row }">
              <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
                unswitch-text="禁用"></lay-switch>
            </template> 

            <template #invitationCode="{ row }">
                <lay-button size="xs" type="primary" @click="copyToClipboard(row.invitationCode)">复制链接</lay-button> 
            </template>
 
            
          </lay-table>


        </lay-row> 

      </div>
    </lay-layer>


</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { addDividedIntoAgent, allDividedIntoGeneralAgent, disableUsers ,adminOneClickLogin,allAdminDividedIntoAgent} from '../../api'
import { setToken,setUserName } from '../../utils/auth' 

const copyToClipboard=(text:any)=> {
  const textarea = document.createElement('textarea');
    textarea.value = text;
    // 将 textarea 添加到 body 中
    document.body.appendChild(textarea);
    // 选中 textarea 中的内容
    textarea.select();
    // 执行复制命令
    document.execCommand('copy');
    // 移除 textarea 元素
    document.body.removeChild(textarea);
    layer.msg('复制成功')
}




//一键登录
const  oneClickLogin=(row: any)=>{ 
  var data={"key":row.userId}; 
  adminOneClickLogin(JSON.stringify(data)).then((res: any) => {
    if (res!=""&&res.code == 0) { 
            setUserName(row.userName);
            setToken(res.data);
            layer.msg("登录成功", { icon : 1, time: 1000})
            window.location.reload();
        } 
      })
 
}



//代理信息

const  isGeneralAgent=ref(false); 
  
const agentMyUserActivity = reactive({ 
  userName: "", 
  proportion: 0, 
  numberSubagents: 0, 
  visitsToday: 0 ,
  totalVisits: 0, 
  todayIp: 0, 
  totalIp: "", 
  totalDowds: 0, 
  dowdsToday:0,
  inviteToday: 0, 
  totalInvite: 0, 
  registerToday: 0, 
  totalRegister: 0, 
  open: 0, 
  closed: 0, 
  threeDays: 0, 
  sevenDays: 0, 
  thirtyDays: 0, 
  univalent:0,
  closedTotalDowds:0,
  closedTotalInvite:0,
  closedTotalRegister:0,
  userId:"",

   

});




  const check = (row: any) => {  
     
    agentMyUserActivity.userId=row.userId;

      isGeneralAgent.value=true; 
      var data = { "key": row.userId };
      geallAdminDividedIntoAgent();
  

}

const dataSourceAgent = ref([]);
const pageAgent = reactive({ current: 1, limit: 10, total: 0 });

const columnsAgent = ref([
    { title: "账号", key: "userName", align: "center",width: "100px" },
    { title: "推广地址", key: "invitationCode", align: "center" , customSlot: "invitationCode",width: "90px"},  
    { title: "分成比例", key: "proportion", align: "center" },
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今注册", key: "registerToday", align: "center"},
    { title: "总注册", key: "totalRegister", align: "center"},
    { title: "今充值", key: "rechargeToday", align: "center" },
    { title: "总充值", key: "totalRecharge", align: "center" },
    { title: "今收益", key: "todayEarnings", align: "center" },
    { title: "总收益", key: "totalRevenue", align: "center" },
    { title: "余额", key: "balance", align: "center" },   
  ]);


  const geallAdminDividedIntoAgent = () => { 
  var data = { "page": pageAgent.current, "limit": pageAgent.limit, "keyWord": agentMyUserActivity.userId };
  allAdminDividedIntoAgent(JSON.stringify(data)).then((res: any) => {
  
    if (res != "" && res.code == 0) {
      dataSourceAgent.value = res.data;
      pageAgent.total = res.count;
    } else {
      dataSourceAgent.value = [];
      pageAgent.total = 0;
    }
  })
}













const search = reactive({
    key: "",
})


const loading = ref(false);

const dataSource = ref([]);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
    { title: "账号", key: "userName", align: "center" },
    { title: "推广地址", key: "invitationCode", align: "center" , customSlot: "invitationCode",width: "90px"},  
    { title: "分成类型", key: "verify", align: "center", customSlot: "verify", ellipsisTooltip: true, },
    { title: "分成比例", key: "proportion", align: "center" },
    { title: "代理人数", key: "numberSubagents", align: "center" }, 
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今注册", key: "registerToday", align: "center"},
    { title: "总注册", key: "totalRegister", align: "center"},
    { title: "今充值", key: "rechargeToday", align: "center" },
    { title: "总充值", key: "totalRecharge", align: "center" },
    { title: "今收益", key: "todayEarnings", align: "center" },
    { title: "总收益", key: "totalRevenue", align: "center" },
    { title: "余额", key: "balance", align: "center" },
    { title: "状态", key: "state", align: "center", customSlot: "state" },
    { title: "U地址", key: "uaddress", align: "center" },
    
    { title: "备注", key: "generalRemark", align: "center" }, 
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "200px" }
]);


const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allDividedIntoGeneralAgent(JSON.stringify(data)).then((res: any) => {
        loading.value = false;
        if (res != "" && res.code == 0) {
            dataSource.value = res.data;
            page.total = res.count;
        } else {
            dataSource.value = [];
            page.total = 0;
        }
    })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
    getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const changedelFlag = (row: any) => {
    var data = { "key": row.userId, "id": row.state };
    disableUsers(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
            getAllAdminUser();
            layer.msg("操作成功", { time: 1000, icon: 1 });
        }
    })
}

//查询接口
const searchUser = () => {
    page.current = 1;
    getAllAdminUser();
}



const agent = reactive({
    userId: "",
    userName: "",
    password: "",
    univalent: "", 
    proportion: "",
    generalRemark: "",
    verify: "0"
})


const updateUser = (row: any) => {
    agent.userId = row.userId;
    agent.userName = row.userName;
    agent.password = ""; 
    agent.univalent = row.univalent;
    agent.proportion = row.proportion;
    agent.verify = row.verify;


    agent.generalRemark = row.generalRemark;

    isDisabled.value = true;
    visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const changeVisible11 = () => {
    agent.userId = "";
    agent.userName = "";
    agent.password = "";
    agent.univalent = ""; 
    agent.proportion = "";
    agent.generalRemark = "";
    agent.verify = "0";
    isDisabled.value = false;
    visible11.value = !visible11.value;
}

const action11 = ref([
    {
        text: "确认",
        callback: () => {
            addDividedIntoAgent(JSON.stringify(agent)).then((res: any) => {
                if (res != "" && res.code == 0) {
                    getAllAdminUser();
                    visible11.value = false;
                    layer.msg("操作成功", { time: 1000, icon: 1 });
                }
            })
        }
    },
    {
        text: "取消",
        callback: () => {
            visible11.value = false;

        }
    }
])

 

</script>
  
<style scoped></style>