<template>
    <lay-row space="10">
        <lay-col md="24">
            <lay-panel>
                <lay-form :model="search" style="text-align: start" size="sm">
                    <lay-form-item label="代理账号" prop="username" mode="inline">
                        <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
                    </lay-form-item>
                    <lay-form-item mode="inline">
                        <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
                    </lay-form-item>

                </lay-form>
                <div style="margin-bottom: 10px;text-align: left;">
                    <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
                </div>

                <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
                    v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

                    <template #state="{ row }">
                        <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)"
                            onswitch-text="正常" unswitch-text="禁用"></lay-switch>
                    </template>
                    <template #verify="{ row }">

                        <p v-if="row.verify == '1'">
                            首充分佣
                        </p>
                        <p v-else-if="row.verify == '2'">
                            长期分佣
                        </p>
                        </template>


                    <template v-slot:operator="{ row }"> 
                        <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
                    </template> 
                </lay-table>



            </lay-panel>

        </lay-col>

    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['450px', '580px']" :btn="action11" title="添加/修改">
        <div style="padding: 20px;">
            <lay-form :model="agent" ref="layFormRef11" :pane="true">
                <lay-form-item label="账户" prop="userName">
                    <lay-input v-model="agent.userName" :disabled="isDisabled" placeholder="请输入账号"></lay-input>
                </lay-form-item>
                <lay-form-item label="密码" prop="password">
                    <lay-input v-model="agent.password" type="password" placeholder="无需修改请置空"></lay-input>
                </lay-form-item>
                <lay-form-item label="分成方式" prop="verifyArr">
                    <lay-select v-model="agent.verify" :multiple="false">
                        <lay-select-option value="1" label="首充分佣"></lay-select-option>
                        <lay-select-option value="2" label="长期分佣"></lay-select-option>
                    </lay-select>
                </lay-form-item>
               
                <lay-form-item label="分成比例" prop="proportion">
                    <lay-input v-model="agent.proportion"  placeholder="请输入分成比例（百分比）">

                    </lay-input>
                </lay-form-item>
                <lay-form-item label="备注" prop="agentRemark">
                    <lay-textarea placeholder="请输入备注" v-model="agent.agentRemark"></lay-textarea>
                </lay-form-item>

            </lay-form>
        </div>
    </lay-layer>
</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { addDividedIntoAgent, allDividedIntoAgent, disableUsers } from '../../api'


const search = reactive({
    key: "",
})


const loading = ref(false);

const dataSource = ref([]);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
    { title: "账号", key: "userName", align: "center" },  
    { title: "分成类型", key: "verify", align: "center", customSlot: "verify", ellipsisTooltip: true, },
    { title: "分成比例", key: "proportion", align: "center" }, 
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今日充值", key: "rechargeToday", align: "center" },
    { title: "总充值", key: "totalRecharge", align: "center" },
    { title: "今日收益", key: "todayEarnings", align: "center" },
    { title: "总收益", key: "totalRevenue", align: "center" }, 
    { title: "状态", key: "state", align: "center", customSlot: "state" },
    { title: "U地址", key: "uaddress", align: "center" },
    { title: "备注", key: "agentRemark", align: "center" },
    { title: "注册时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
]);


const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allDividedIntoAgent(JSON.stringify(data)).then((res: any) => {
        loading.value = false;
        if (res != "" && res.code == 0) {
            dataSource.value = res.data;
            page.total = res.count;
        } else {
            dataSource.value = [];
            page.total = 0;
        }
    })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
    getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const changedelFlag = (row: any) => {
    var data = { "key": row.userId, "id": row.state };
    disableUsers(JSON.stringify(data)).then((res: any) => {
        if (res != "" && res.code == 0) {
            getAllAdminUser();
            layer.msg("操作成功", { time: 1000, icon: 1 });
        }
    })
}

//查询接口
const searchUser = () => {
    page.current = 1;
    getAllAdminUser();
}



const agent = reactive({
    userId: "",
    userName: "",
    password: "",
    univalent: "", 
    proportion: "",
    agentRemark: "", 
    verify:"0",
})


const updateUser = (row: any) => {
    agent.userId = row.userId;
    agent.userName = row.userName;
    agent.password = ""; 
    agent.univalent = row.univalent;
    agent.proportion = row.proportion; 
    agent.verify =row.verify ;  
    agent.agentRemark = row.agentRemark;
    isDisabled.value = true;
    visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const changeVisible11 = () => {
    agent.userId = "";
    agent.userName = "";
    agent.password = "";
    agent.univalent = ""; 
    agent.proportion = "";
    agent.agentRemark = ""; 
    agent.verify ="0"; 
    
    isDisabled.value = false;
    visible11.value = !visible11.value;
}

const action11 = ref([
    {
        text: "确认",
        callback: () => {
            addDividedIntoAgent(JSON.stringify(agent)).then((res: any) => {
                if (res != "" && res.code == 0) {
                    getAllAdminUser();
                    visible11.value = false;
                    layer.msg("操作成功", { time: 1000, icon: 1 });
                }
            })
        }
    },
    {
        text: "取消",
        callback: () => {
            visible11.value = false;

        }
    }
])

 

</script>
  
<style scoped></style>